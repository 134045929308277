import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AdsGETApi, LogoURL } from '../../Constant/api';
import { slugs } from '../../Constant/slugs';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export default function Home() {
  const dispatch = useDispatch()
  const [Sliders, setSliders] = React.useState([])
  const fetchSliders = () => {
    AdsGETApi({ _fields: "meta" }, "image_home/131")
      .then((res) => {
        // if (res.data.length !== 0)
        if (res.data.meta.picture_url_list.length !== 0)
          setSliders(JSON.parse(res.data.meta.picture_url_list))
      })
  }

  useEffect(() => {
    fetchSliders()
  }, [])

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#fff", marginTop: 10, paddingBottom: "50px" }}>
      <div className="row">
        <div style={{ margin: "10px" }} className="center">
          <img
            src={LogoURL}
            style={{ height: "30vh", width: "30vh", objectFit: "contain" }}
          />
          {/* {JSON.stringify(Sliders)} */}
          {/* <div style={{ width: "100%" }}>
            <Carousel
              dynamicHeight={true}
              showThumbs={false}
              infiniteLoop={true}
              autoPlay={true}
              transitionTime={500}
              interval={4500}
              centerMode={true}
              centerSlidePercentage={100}
            >
              {Sliders.map((item, index) => (
                <div key={index}
                  className="Carosel-Image"
                >
                  <img src={item}
                    style={{
                      width: "100%", height: "50vh",

                      objectFit:"cover"
                    }}
                  />
                </div>
              ))}
            </Carousel>
          </div> */}
        </div>
        <div className="row" >
          <Link to={slugs.daily_exam} className="col-4 center" style={{ padding: "20px" }}>
            <img
              className="home-icons"
              src="https://api.lpktayo.com/wp-content/uploads/2023/11/class.jpeg"
            />
            <div className="home-fonts">
              Kelas
            </div>
          </Link>
          <Link to={slugs.exam} className="col-4 center" style={{ padding: "20px" }}>
            <img
              className="home-icons"
              src="https://api.lpktayo.com/wp-content/uploads/2023/11/exam.jpeg"
            />
            <div className="home-fonts">
              PEMANTAPAN
            </div>
          </Link>
          <Link to={slugs.cbtexam} className="col-4 center" style={{ padding: "20px" }}>
            <img
              className="home-icons"
              src="https://api.lpktayo.com/wp-content/uploads/2023/11/ubtspecial.jpeg"
            />
            <div className="home-fonts">
              UBT SPECIAL
            </div>
          </Link>

        </div>

        <div className="row">
          <Link to={slugs.book_list_user} className="col-4 center">
            <img
              className="home-icons"
              src="https://api.lpktayo.com/wp-content/uploads/2023/11/books.jpeg"
            />
            <div className="home-fonts">
              MATERI BELAJAR
            </div>
          </Link>
          <Link to={"/profile"} className="col-4 center">
            <img
              className="home-icons"
              src="https://api.lpktayo.com/wp-content/uploads/2023/11/profile.jpeg"
            />
            <div className="home-fonts">
              PROFIL
            </div>
          </Link>
          <Link to={"/my-exam-results"} className="col-4 center">
            <img
              className="home-icons"
              src="https://api.lpktayo.com/wp-content/uploads/2023/11/history.jpeg"
            />
            <div className="home-fonts">
              HISTORY TEST
            </div>
          </Link>

        </div>

      </div>
    </div>
  )
}