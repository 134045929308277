import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApi, AdsPOSTApi, AdsPUTApi } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
import FileUpload from "../../FileUpload";
import NotFound from "../../NotFound";
import PicturesUpload from "./MediaArrayUpload";

export default function PDFSingleApp(props) {

  const history = useHistory()

  const [NotFoundData, setNotFound] = React.useState(false)

  const [reload, setReload] = React.useState(true)
  const [ImageArray, setImageArray] = React.useState([])
  const [PDF, setPDF] = React.useState([])
  const [ImageArrayReload, setImageArrayReload] = React.useState("")
  const location = useLocation()
  var Thisslug = "/PDFSingleApp/"
  var id = location.pathname.slice(Thisslug.length)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")
  const EditInfo = () => {
    setReload(true)
    const data = {
      "meta": {
        pdf_url: PDF
      }
    }
    AdsPUTApi(data, "image_home", id)
      .then((res) => {
        setReload(false)
        setAlertShow(true)
      }).catch(err => {
        console.log(err)
        console.warn(err.response.data)
        console.log("Response Status: hello11", err.response.status);
        console.log("Response Headers:", err.response.headers);
        setReload(false)
        setAlertShow(true)
        setAlertcomp(err.response.data.message)
      })
  }
  useEffect(() => {
    fetchSliders(location.pathname.slice(Thisslug.length))
    return history.listen((location) => {
      if (location.pathname.slice(Thisslug.length)) {
        window.scrollTo(0, 0)
        fetchSliders(location.pathname.slice(Thisslug.length))
      }
    })
  }, [history])
  const fetchSliders = (ID) => {
    setNotFound(false)
    setImageArrayReload(false)
    AdsGETApi({ _fields: "meta" }, `image_home/${ID}`)
      .then((res) => {
        setReload(false)
        console.log(res.data)
        setImageArray(res.data)
        setPDF(res.data.meta.pdf_url)
        setImageArrayReload(true)
      })
      .catch(() => {
        setNotFound(true)
      })
  }

  return (
    <div>
      {!reload ?
        <div>
          {NotFoundData ?
            <NotFound /> :
            <div>
              <div>
                <div className="row" style={{ padding: "30px 30px" }}>
                  <div className="col-md-8 button">
                    <h2>{ImageArray.meta.pdf_title}</h2>
                  </div>
                  <div className="col-md-4" style={{ textAlign: 'right' }}>
                    <a href="/">Dashboard</a> / <a href={slugs.all_books}>All Image</a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2 col-1"></div>
                  <div className="col-sm-8 col-10 form-view mb-3">
                    <h4><center>PDF Upload</center></h4>
                    <table>
                      <tbody>
                        <tr>
                          <FileUpload
                            ImageUrl={PDF}
                            setImageUrl={setPDF}
                          />
                        </tr>
                      </tbody>
                    </table>
                    <div className="row center m-2">
                      <div className=" col-3" style={{ padding: "0 10px" }}>
                        <div className="sign-in-button-4"
                          onClick={() => {
                            EditInfo()
                          }}
                        >
                          Update PDF
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        :
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }

    </div >
  )

}