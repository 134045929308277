// import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
// import DataTable from "react-data-table-component";
// import { AdsGETApiAuth, AdsPUTApi } from "../../../Constant/api";
// import { slugs } from "../../../Constant/slugs";
// const columns = [
//   {
//     id: 1,
//     name: "id",
//     selector: (row) => row.id,
//     sortable: true,
//     reorder: true,
//   },
//   {
//     id: 31,
//     name: "Title",
//     selector: (row) => row.title.rendered,
//     sortable: true,
//     reorder: true,
//   },
//   {
//     id: 32,
//     name: "Exam Time",
//     selector: (row) => row.meta.exam_time,
//     sortable: true,
//     reorder: true
//   },
//   {
//     id: 32,
//     name: "Date",
//     selector: (row) => row.date.slice(0, 10),
//     sortable: true,
//     reorder: true
//   },
//   {
//     id: 33,
//     name: "Availability",
//     selector: (row) => row.meta.available_unavailable,
//     sortable: true,
//     reorder: true,
//     width: "150px"

//   },
//   {
//     id: 34,
//     name: "Type",
//     selector: (row) => row.meta.exam_type,
//     sortable: true,
//     reorder: true
//   },
//   {
//     id: 34,
//     name: "Batch",
//     selector: (row) => row.meta.batch_name,
//     sortable: true,
//     reorder: true
//   },
//   {
//     id: 3,
//     name: "View",
//     right: true,
//     cell: (row) => <Link to={`${slugs.daily_exam_details}${row.slug}`}>View</Link>,

//   }
// ];

// export default function AllDailyExam() {
//   const [Projects1, setProjects1] = React.useState([])
//   const [Projects2, setProjects2] = React.useState([])
//   const [CustomersList, setCustomersList] = React.useState([])
//   const [Reload, setReload] = React.useState(false)
//   const [Available, setAvailable] = React.useState(0)
//   const [UnAvailable, setUnAvailable] = React.useState(0)

//   const GetAllProjects = (params) => {
//     setReload(true)
//     setProjects1([])
//     AdsGETApiAuth(params, slugs.db_slug_daily_exam)
//       .then((res) => {
//         setProjects1(res.data)
//         setCustomersList(res.data)
//         console.log("done")
//       })
//       .catch(err => {
//         console.log(err)
//       }).finally(() => {
//         setReload(false)
//       })
//   }
//   useEffect(() => {
//     GetProject()
//   }, [])
//   const GetProject = () => {
//     GetAllProjects({
//       per_page: 100,
//       _fields: "id,title,slug,date,meta.exam_time,meta.available_unavailable,meta.exam_type,meta.batch_name"
//     })

//   }

//   return (
//     <div>
//       <div className="row" style={{ padding: "30px 30px" }}>
//         <div className="col-md-8 button">
//           <h2>All Batch Exams</h2>
//         </div>
//         <div className="col-md-4" style={{ textAlign: 'right' }}>
//           <a href="/">Dashboard</a> / All Daily Exams
//         </div>
//       </div>
//       <a href={slugs.new_daily_exam}>
//         <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
//           onClick={() => {

//           }}
//         >Add New Daily Exam</div>
//       </a>
//       <div className="row">
//         <div style={{ display: "inline", cursor: "pointer" }}>
//           <div style={{ display: "inline-block", margin: "0px 5px" }}
//             onClick={() => {
//               setProjects2(CustomersList)
//             }}
//           >
//             All ({CustomersList.length}) |
//           </div>
//           <div style={{ display: "inline-block", margin: "0px 5px" }}
//             onClick={() => {
//               setProjects2(CustomersList.filter(a => a.meta.available_unavailable == "Available"))
//             }}
//           >
//             Available ({Available}) |
//           </div>
//           <div style={{ display: "inline-block", margin: "0px 5px" }}
//             onClick={() => {
//               setProjects2(CustomersList.filter(a => a.meta.available_unavailable == "Unavailable"))
//             }}
//           >
//             Unavailable ({UnAvailable}) |
//           </div>
//           {Projects1.filter(a => a.meta.available_unavailable == "Unavailable").length !== 0 &&
//             <div style={{ display: "inline-block", margin: "0px 5px" }}
//               onClick={() => {
//                 for (let i = 0; i < Projects1.length; i++) {
//                   setReload(true)
//                   AdsPUTApi({
//                     meta: {
//                       "available_unavailable": "Available",
//                     }
//                   }, slugs.db_slug_paid_exam, Projects1[i].id)
//                     .then(res => {
//                       if (i == Projects1.length - 1) {
//                         setProjects1([])
//                         GetProject()
//                       }
//                     })
//                 }
//               }
//               }
//             >
//               Make Available |
//             </div>
//           }
//           {Projects1.filter(a => a.meta.available_unavailable == "Available").length !== 0 &&
//             <div style={{ display: "inline-block", margin: "0px 5px" }}
//               onClick={() => {
//                 for (let i = 0; i < Projects1.length; i++) {
//                   setReload(true)
//                   AdsPUTApi({
//                     meta: {
//                       "available_unavailable": "Unavailable",
//                     }
//                   }, slugs.db_slug_paid_exam, Projects1[i].id)
//                     .then(res => {
//                       if (i == Projects1.length - 1) {
//                         setProjects1([])
//                         GetProject()
//                       }
//                     })
//                 }
//               }
//               }
//             >
//               Make Unavailable
//             </div>
//           }
//         </div>
//       </div>

//       <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
//         <DataTable
//           columns={columns}
//           data={CustomersList}
//           defaultSortFieldId={1}
//           defaultSortAsc={false}
//           striped
//           // sortIcon={<SortIcon />}
//           pagination
//           selectableRows
//           paginationPerPage={100}
//           paginationRowsPerPageOptions={[10, 50, 100, 1000]}
//         />
//       </div>
//       {Reload &&
//         <div className="modal" style={{ padding: "50vw" }}>
//           <div className="dot-pulse"></div>
//         </div>
//       }
//     </div>
//   )
// }


import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsGETApiAuth, AdsPUTApi } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
const columns = [
  {
    id: 1,
    name: "id",
    selector: (row) => row.id,
    sortable: true,
    reorder: true,
    width: "100px"
  },
  {
    id: 31,
    name: "Title",
    selector: (row) => row.title.rendered,
    sortable: true,
    reorder: true,
  },
  {
    id: 32,
    name: "Waktu Mengerjakan",
    selector: (row) => row.meta.exam_time,
    sortable: true,
    reorder: true
  },
  {
    id: 321,
    name: "Kode Ujian",
    selector: (row) => row.meta.exam_code,
    sortable: true,
    reorder: true
  },
  {
    id: 33,
    name: "Tersedia",
    selector: (row) => row.meta.available_unavailable == "Available" ? "Tersedia" : "Tidak tersedia",
    sortable: true,
    reorder: true,
    width: "150px"
  },
  {
    id: 341,
    name: "Kelas",
    selector: (row) => row.meta.batch_name,
    sortable: true,
    reorder: true
  },
  {
    id: 3,
    name: "Lihat",
    right: true,
    cell: (row) => <Link to={`${slugs.daily_exam_details}${row.slug}`}>Lihat</Link>,

  }
];

export default function AllDailyExam() {
  const [Projects1, setProjects1] = React.useState([])
  const [Projects2, setProjects2] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [Available, setAvailable] = React.useState(0)
  const [UnAvailable, setUnAvailable] = React.useState(0)
  const [batchList, setBatchList] = React.useState([])
  const [batchID, setBatchID] = React.useState("")
  const [batchName, setBatchName] = React.useState("")
  const [choose, setChoose] = React.useState(1)
  const [ChangeToStudentBatchModal, setChangeToStudentBatchModal] = React.useState(false)

  const [Reload, setReload] = React.useState(false)
  const GetAllProjects = (params) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slugs.db_slug_daily_exam)
      .then((res) => {
        setProjects2(res.data)
        setCustomersList(res.data)
        setAvailable(res.data.filter(a => a.meta.available_unavailable == "Available").length)
        setUnAvailable(res.data.filter(a => a.meta.available_unavailable == "Unavailable").length)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  const GetProject = () => {
    GetAllProjects({
      per_page: 100,
      _fields: "id,title,slug,date,meta.exam_time,meta.available_unavailable,meta.exam_type,meta.batch_name,meta.batch_id,meta.exam_code"
    })
    BatchList()

  }
  useEffect(() => {
    GetProject()
  }, [])

  const BatchList = () => {
    AdsGETApiAuth(
      { per_page: 100, _fields: "id,title,meta.batch_status,meta.batch_start_date,meta.batch_end_date" },
      slugs.db_slug_batch
    )
      .then((res) => {
        setBatchList(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }
  const columnsBatch = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
    },
    {
      id: 31,
      name: "Title",
      selector: (row) => row.title.rendered,
      sortable: true,
      reorder: true
    },
    {
      id: 312,
      name: "Total",
      selector: (row) => CustomersList.filter(a => a.meta.batch_id == row.id).length,
      sortable: true,
      reorder: true
    },
    {
      id: 312,
      name: "Status Kelas",
      selector: (row) => row.meta.batch_status,
      sortable: true,
      reorder: true
    },
    {
      id: 3,
      name: "View",
      right: true,
      cell: (row) => <div style={{ cursor: "pointer" }}
        onClick={() => {
          const ResultFilter = CustomersList.filter(a => a.meta.batch_id == row.id)
          setProjects2(ResultFilter)
          setChoose(0)
        }}
      >
        Lihat
      </div>,

    }
  ];

  return (
    <div style={{}}>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>Soal Tryout Mingguan</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / Soal Tryout Mingguan
        </div>
      </div>
      <a href={slugs.new_daily_exam}>
        <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
          onClick={() => {

          }}
        >Tambah Soal Tryout</div>
      </a>
      <div className="row">
        <div style={{ display: "inline", cursor: "pointer" }}>
          <div style={{ display: "inline-block", margin: "0px 5px" }}
            onClick={() => {
              setChoose(0)
              setProjects2(CustomersList)
            }}
          >
            Semua ({CustomersList.length}) |
          </div>
          <div style={{ display: "inline-block", margin: "0px 5px" }}
            onClick={() => {
              setChoose(0)
              setProjects2(CustomersList.filter(a => a.meta.available_unavailable == "Available"))
            }}
          >
            Tersedia ({Available}) |
          </div>
          <div style={{ display: "inline-block", margin: "0px 5px" }}
            onClick={() => {
              setChoose(0)
              setProjects2(CustomersList.filter(a => a.meta.available_unavailable == "Unavailable"))
            }}
          >
            Tidak tersedia ({UnAvailable}) |
          </div>
          <div style={{ display: "inline-block", margin: "0px 5px" }}
            onClick={() => {
              const subscriberList = CustomersList.filter(a => a.meta.user_role == "author")
              setChoose(1)
            }}
          >
            Kelas ({batchList.length}) |
          </div>
          <div style={{ display: "inline-block", margin: "0px 5px", cursor: "pointer" }}
            onClick={() => {
              setChangeToStudentBatchModal(true)
            }}
          >
            Salin Ujian |
          </div>
          {Projects1.filter(a => a.meta.available_unavailable == "Unavailable").length !== 0 &&
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                for (let i = 0; i < Projects1.length; i++) {
                  setReload(true)
                  AdsPUTApi({
                    meta: {
                      "available_unavailable": "Available",
                    }
                  }, slugs.db_slug_daily_exam, Projects1[i].id)
                    .then(res => {
                      if (i == Projects1.length - 1) {
                        setProjects1([])
                        GetProject()
                      }
                    })
                }
              }
              }
            >
              Buat Tersedia |
            </div>
          }
          {Projects1.filter(a => a.meta.available_unavailable == "Available").length !== 0 &&
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                for (let i = 0; i < Projects1.length; i++) {
                  setReload(true)
                  AdsPUTApi({
                    meta: {
                      "available_unavailable": "Unavailable",
                    }
                  }, slugs.db_slug_daily_exam, Projects1[i].id)
                    .then(res => {
                      if (i == Projects1.length - 1) {
                        setProjects1([])
                        GetProject()
                      }
                    })
                }
              }
              }
            >
              Simpan ke Draft
            </div>
          }
        </div>
      </div>

      <div style={{ backgroundColor: "#fff", padding: "10px", width: "98%", margin: "10px", border: "3px solid #eee" }}>
        {choose == 0 &&
          <DataTable
            paginationPerPage={100}
            paginationRowsPerPageOptions={[10, 50, 100, 1000]}
            columns={columns}
            data={Projects2}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            // sortIcon={<SortIcon />}
            pagination
            selectableRows
            onSelectedRowsChange={(r) => {
              console.log("asdasd", r)
              setProjects1(r.selectedRows)
            }}
          />
        }
        {choose == 1 &&
          <DataTable
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 50, 100, 1000]}
            columns={columnsBatch}
            data={batchList}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            pagination
            selectableRows={false}
          />
        }
      </div>
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {ChangeToStudentBatchModal &&
        <>
          <section className="modal" >
            <div className="row" style={{ overflow: "hidden" }}>
              <div className="col-md-3 col-1"></div>
              <div className="col-md-6 col-10  shadow bg-white">
                <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                  <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                    onClick={() => {
                      setChangeToStudentBatchModal(false)
                    }}
                  >X</div>
                  Apakah anda yakin untuk mengubah soal dalam kelas ?<br />
                  {/* {batchID} */}
                  <div className="row">
                    <div className="col-3 center " >
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setChangeToStudentBatchModal(false)
                        }}
                      >
                        batal
                      </div>
                    </div>
                    <div className="col-3 center">
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setChangeToStudentBatchModal(false)
                          for (let i = 0; i < Projects1.length; i++) {
                            setReload(true)
                            AdsPUTApi({
                              meta: {
                                "batch_id": "",
                                "batch_name": "",
                              }
                            }, slugs.db_slug_daily_exam, Projects1[i].id)
                              .then((res) => {
                                if (i == Projects1.length - 1) {
                                  GetProject()
                                }
                              })
                          }
                        }}
                      >
                        Hapus Soal
                      </div>
                    </div>
                    <div className="col-3 center">
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setChangeToStudentBatchModal(false)
                          for (let i = 0; i < Projects1.length; i++) {
                            setReload(true)
                            AdsPUTApi({
                              meta: {
                                "batch_id": batchID,
                                "batch_name": batchName,
                              }
                            }, slugs.db_slug_daily_exam, Projects1[i].id)
                              .then((res) => {
                                if (i == Projects1.length - 1) {
                                  GetProject()
                                }
                              })
                          }
                        }}
                      >
                        Salin ke Kelas
                      </div>
                    </div>
                  </div>
                  <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px", border: "1px solid #999" }}>
                    <table>
                      <tbody>
                        {batchList.map((item, index) => (
                          <tr className="row bg-white shadow" key={index}
                            style={{ cursor: "pointer", border: "1px solid #999" }}
                            onClick={() => {
                              if (batchID != item.id) {
                                setBatchID(`${item.id}`)
                                setBatchName(item.title.rendered)
                              }
                              else {
                                setBatchID(``)
                                setBatchName("")
                              }
                            }}
                          >
                            <td className="col-1">
                              {batchID != item.id ?
                                <i className="fa fa-square-o" />
                                :
                                <i className="fa fa-check-square-o" />
                              }
                            </td>
                            <td className="col-2" >
                              {item.id}
                            </td>
                            <td className="col-3">
                              {item.title.rendered}
                            </td>
                            <td className="col-3">
                              {item.meta.batch_start_date}
                            </td>
                            <td className="col-3">
                              {item.meta.batch_end_date}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <table>
            <tbody>
              {batchList.map((item, index) => (
                <tr className="row bg-white shadow" key={index}
                  style={{ cursor: "pointer", border: "1px solid #999" }}
                  onClick={() => {
                  }}
                >
                  <td className="col-1">
                    {batchID !== item.id ?
                      <i className="fa fa-square-o" />
                      :
                      <i className="fa fa-check-square-o" />
                    }
                  </td>
                  <td className="col-2" >
                    {item.id}
                  </td>
                  <td className="col-3">
                    {item.title.rendered}
                  </td>
                  <td className="col-3">
                    {item.meta.batch_start_date}
                  </td>
                  <td className="col-3">
                    {item.meta.batch_end_date}
                  </td>
                </tr>
              ))}
            </tbody>
          </table> */}
        </>
      }
    </div>
  )
}