import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { slugs } from '../../../Constant/slugs';
import './styles.css'
export default function FinalScreenExam(props) {
  const {
    ExamData,
    QuestionsData,
    AnswerArray,
    userDetails,
    ResultData
  } = props;
  const [totalAttempt, setTotalAttempt] = React.useState(0)
  const [correctAnswer, setCorrectAnswer] = React.useState(0)
  const location = useLocation()
  useEffect(() => {
    var attempt = 0;
    var correct = 0;
    for (let i = 0; i < AnswerArray.length; i++) {
      if (AnswerArray[i].solved == 1)
        attempt++;
      if (AnswerArray[i].correct_answer == AnswerArray[i].answerChoosen)
        correct++
      setTotalAttempt(attempt);
      setCorrectAnswer(correct)
    }
  }, [])
  return (
    <div style={{ padding: "20px" }}>
      <h2>Congratulations! </h2>
      <br />
      <br />

      <center>Name: {userDetails.name}</center>
      <center>Exam: {ExamData.title.rendered}</center>
      <br />
      <br />

      <div className="row row-data">
        <div className="col-8">Jumlah soal</div>
        <div className="col-4">{Object.keys(QuestionsData).length}</div>
      </div>
      <div className="row row-data">
        <div className="col-8">Jumlah yang dijawab</div>
        <div className="col-4">{totalAttempt}</div>
      </div>
      <div className="row row-data">
        <div className="col-8">jawaban benar</div>
        <div className="col-4">{correctAnswer}</div>
      </div>
      <div className="row row-data">
        <div className="col-8">Point(out of 100)</div>
        <div className="col-4">{(correctAnswer/Object.keys(QuestionsData).length*100).toFixed(1)}</div>
      </div>
      {location.pathname.includes(slugs.start_exam) &&
        <div className="row center">
          <Link to={`${slugs.exam_result}/${ResultData.slug}`} className="col-lg-2 col-12 Link">
            <div className="col-12 sign-in-button-1">
              View All Answers
            </div>
          </Link>
        </div>
      }
      {location.pathname.includes(slugs.start_daily_exam) &&
        <div className="row center">
          <Link to={`${slugs.daily_exam_result}/${ResultData.slug}`} className="col-lg-2 col-12 Link">
            <div className="col-12 sign-in-button-1">
              View All Answers
            </div>
          </Link>
        </div>
      }
      <div className="row center">
        <Link to={"/"} className="col-lg-2 col-12 Link">

          <div className="col-12 sign-in-button-1">
            Home
          </div>
        </Link>
      </div>
    </div>
  )
}