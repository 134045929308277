// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useHistory, useLocation } from 'react-router-dom';
// import { AdsGETApiAuth, userIcon } from '../../../Constant/api';
// import { slugs } from '../../../Constant/slugs';
// import store from '../../../redux/store';
// import NotFound from '../../NotFound';
// import FirstScreenExam from '../ExamDetails';

// export default function StartExam(props) {
//   const dispatch = useDispatch()
//   const location = useLocation()
//   const history = useHistory();
//   const [ExamData, setAllExamList] = React.useState({});
//   const [ExamFetch, setExamFetch] = React.useState(false);
//   const [NotFoundData, setNotFound] = React.useState(false)
//   const [reload, setReload] = React.useState(false)
//   const [choose, setChoose] = React.useState(0)
//   const [AnswerArray, setAnswerArray] = React.useState([]);
//   const [ResultData, setResultData] = React.useState({});
//   const LoginUser = useSelector((state) => state.LoginState);

//   const fetchExam = (slug) => {
//     setExamFetch(false)
//     setReload(true)
//     setNotFound(false)
//     const id = store.getState().LoginState.userID
//     AdsGETApiAuth({ slug: slug }, slugs.db_slug_paid_exam)
//       .then((res) => {
//         if (res.data.length == 1) {
//           if (res.data[0].meta.exam_question_block_text !== undefined)
//             setAllExamList(res.data[0])
//           else {
//             setNotFound(true)
//           }
//           setExamFetch(true)
//           setReload(false)
//         }
//         else {
//           setNotFound(true)
//           setExamFetch(true)
//           setReload(false)
//         }
//       })
//       .catch(err => {
//         console.log(err)
//         console.log(err.response.data)
//       })

//   }
//   const [finishStatus, setfinishStatus] = React.useState(false);

//   useEffect(() => {
//     fetchExam(location.pathname.slice(slugs.start_exam.length + 1))
//   }, [])

//   return (
//     <div style={{ backgroundColor: "#eee", minHeight: "90vh" }}>
//       <div>
//         <div>
//           <div className="form-view m-3" style={{width:"97%"}}>
//             {ExamFetch &&
//               <>
//                 {NotFoundData ?
//                   <NotFound /> :
//                   <>
//                     <div className=" d-sm-block d-none">
//                       <FirstScreenExam
//                         ExamData={ExamData}
//                         setReload={setReload}
//                         choose={choose}
//                         setChoose={setChoose}
//                         AnswerArray={AnswerArray}
//                         setAnswerArray={setAnswerArray}
//                         ResultData={ResultData} setResultData={setResultData}
//                       />
//                     </div>
//                     <div className="d-sm-none d-block">
//                       <div style={{ display: 'flex', justifyContent: 'center', padding: "30px" }}>
//                         <div style={{ width: "100%" }}>
//                           <div style={{ textAlign: 'center' }}>
//                             <h1>{ExamData.title.rendered}</h1>
//                             <br />
//                             {LoginUser.userPhoto === undefined ?
//                               <img src={userIcon}
//                                 height="30px"
//                               />
//                               :
//                               <img src={`${LoginUser.userPhoto}`} className={"height-width-150 border border-dark"} />
//                             }
//                           </div>
//                           <div>
//                             <br />
//                             <b>Name of Student: {LoginUser.userName}</b>
//                             <br />
//                             <b>Student Email: {LoginUser.userEmail}</b>
//                             <br />
//                           </div>
//                           <div>
//                             <b>Exam description</b>
//                             <br />
//                             <div dangerouslySetInnerHTML={{ __html: ExamData.meta.exam_details }} />
//                             {/* {ExamData.content.rendered} */}
//                           </div>
//                           <div style={{ fontWeight: "bold" }}>
//                             The Exam will be best suitable for landscape view.<br />
//                             Please Rotate your screen
//                           </div>
//                           <br />
//                           <div className="row">
//                             <div className="col-lg-4 col-12" />
//                             <div className="col-lg-4 col-12 center">
//                               <button className="sign-in-button-4 col-lg-6 col-6"
//                                 onClick={() => {
//                                   history.goBack()
//                                 }}
//                               >
//                                 Cancel
//                               </button>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </>
//                 }
//               </>
//             }
//           </div>
//         </div>
//       </div>
//       {reload &&
//         <div className="modal" style={{ padding: "50vw" }}>
//           <div className="dot-pulse"></div>
//         </div>
//       }
//     </div>
//   )
// }

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import store from '../../../redux/store';
import NotFound from '../../NotFound';
import FirstScreenExam from '../ExamDetails';

export default function StartWeeklyExam(props) {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const [ExamData, setAllExamList] = React.useState({});
  const [ExamFetch, setExamFetch] = React.useState(false);
  const [NotFoundData, setNotFound] = React.useState(false)
  const [reload, setReload] = React.useState(false)
  const [choose, setChoose] = React.useState(0)
  const [AnswerArray, setAnswerArray] = React.useState([]);
  const [ResultData, setResultData] = React.useState({});

  const fetchExam = (slug) => {
    setExamFetch(false)
    setReload(true)
    setNotFound(false)
    const id = store.getState().LoginState.userID
    console.log(id)
    AdsGETApiAuth({ slug: slug }, slugs.db_slug_paid_exam)
      .then((res) => {
        console.log(res.data)
        if (res.data.length == 1) {
          if (res.data[0].meta.exam_question_block_text !== undefined)
            setAllExamList(res.data[0])
          else {
            setNotFound(true)
          }
          setExamFetch(true)
        }
        else {
          setNotFound(true)
          setExamFetch(true)
          setReload(false)
        }
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
      })
      .finally(()=>{
        setReload(false)
      })

  }
  const [finishStatus, setfinishStatus] = React.useState(false);
  const [Code, setCode] = React.useState("")
  const [CodeGet, setCodeGet] = React.useState("")
  const [StartPressed, setStartPressed] = React.useState(true)

  useEffect(() => {
    const codeget = localStorage.getItem("weekly_exam_code")
    setCodeGet(codeget)
    fetchExam(location.pathname.slice(slugs.start_exam.length + 1))
  }, [])

  return (
    <div style={{ backgroundColor: "#eee", minHeight: "90vh" }}>
      <div>
        <div className="row">
          <div className="col-1"></div>
          <div className="col-10 form-view m-3">
            {ExamFetch &&
              <>
                {NotFoundData ?
                  <NotFound /> :
                  <>
                    {ExamData.meta.exam_code !== CodeGet && StartPressed &&
                      <div className="modal">
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title">Exam Code</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                onClick={() => {
                                  history.goBack()
                                }}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <p>Please Enter The Exam Code to start with your exam.</p>
                              <input className="input-common"
                                value={Code} placeholder="Exam Code"
                                onChange={(event) => { setCode(event.target.value) }}
                              />
                            </div>
                            <div class="modal-footer">
                              {Code == ExamData.meta.exam_code ?
                                <button type="button" class="btn btn-primary"
                                  onClick={() => {
                                    localStorage.setItem("weekly_exam_code", Code)
                                    setStartPressed(false)
                                  }}
                                >Start Exam</button>
                                :
                                <div type="button" class="btn btn-dark">Start Exam</div>
                              }
                              <button type="button" class="btn btn-secondary" data-dismiss="modal"
                                onClick={() => {
                                  history.goBack()
                                }}
                              >Cancel</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    <FirstScreenExam
                      ExamData={ExamData}
                      setReload={setReload}
                      choose={choose}
                      setChoose={setChoose}
                      AnswerArray={AnswerArray}
                      setAnswerArray={setAnswerArray}
                      ResultData={ResultData}
                      setResultData={setResultData}
                    />
                  </>
                }
              </>
            }
          </div>
        </div>
      </div>
      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}