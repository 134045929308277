export const slugs = {
  "new_book": "/new-materai-beljar",
  "books_details": "/admin/materai-beljar/",
  "all_books": "/all-materai-beljar",
  "db_slug_book": "books",

  "new_daily_exam": "/new-tryout-mingguan",
  "daily_exam_details": "/tryout-mingguan/",
  "all_daily_exam": "/all-tryout-mingguan",
  "db_slug_daily_exam": "daily_exam",

  "new_exam": "/new-pemantapan",
  "exam_details": "/pemantapan/",
  "all_exam": "/all-pemantapan",
  "db_slug_paid_results": "paid_exam",
  
  "new_cbt_exam": "/new-ubt-special",
  "cbt_exam_details": "/ubt-special/",
  "all_cbt_exam": "/all-ubt-special",

  "db_slug_paid_exam": "paid_exam",
  "result_admin_view":"/result-admin-view",
  "daily_result_admin_view":"/kelas-result-admin-view",

  "result_details": "/history-pemantapan/",
  "all_results": "/all-history-pemantapan",
  "db_slug_paid_results": "paid_results",

  "daily_result_details": "/history-per-kelas/",
  "all_daily_results": "/all-history-per-kelas-result",
  "db_slug_daily_results": "daily_results",

  "new_student": "/new-siswa",
  "student_details": "/siswa/",
  "all_students": "/all-siswa",
  "db_slug_user_data": "user_data",

  "new_batch": "/new-kelas",
  "batch_details": "/kelas/",
  "all_batch": "/all-kelas",
  "db_slug_batch": "student_batch",

  "exam": "/all-exams-list",
  "cbtexam": "/all-cbt-exams-list",
  "textbookexam": "/all-text-book-exams-list",
  "demo_exam": "/all-demo-exam-list",
  "chapter_wise_exam": "/all-chapters-list",
  "daily_exam": "/all-daily-exam-list",
  "book_list_user": "/all-book-list",

  "start_exam": "/start-exam",
  "start_demo_exam": "/start-demo-exam",
  "start_chapter_wise_exam": "/start-chapter-exam",
  "start_daily_exam": "/start-daily-exam",

  "exam_result_list": "/my-exam-results",
  "daily_exam_result_list": "/my-daily-results",

  "exam_result": "/exam-result",
  "daily_exam_result": "/daily-exam-result",
  "ljd_exam_result_list": "/ljd-exam-result-list",
  "ljd_exam_result": "/ljd-exam-result",
}