import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { slugs } from '../Constant/slugs';
import '../styles.css'
import { LogoURL } from '../Constant/api';
const SideBarBlock = ({
  allSlug, newSlug, location,
  Title, icon, setExpand, Expand,
  setMenuExpand
}) => {
  return (
    <div>
      <div className={location.pathname == allSlug ? "row active-sidebar" : "row passive-sidebar"}

      >
        <Link className={"Link col-10"} to={allSlug}>

          <div className={location.pathname == allSlug ? "active-sidebar-font" : "passive-sidebar-font"}
            onClick={() => {
              setMenuExpand(false)
            }}
          >
            &nbsp; &nbsp;<i className={icon} style={{ fontSize: "10px" }} />
            &nbsp; {Title}
          </div>
        </Link>
        {newSlug !== "" &&
          <div className="col-1" style={{ cursor: 'pointer' }}
            onClick={() => {
              setExpand(!Expand)
            }}>
            <i className={Expand ? "fa fa-minus" : "fa fa-plus"} style={{ fontSize: "10px" }} />
          </div>
        }
      </div>
      {Expand && newSlug !== "" &&
        <>
          {/* <Link className={location.pathname == allSlug ? "Link active-sidebar" : "Link passive-sidebar"}
            style={{ paddingLeft: "40px" }}
            to={allSlug}
          >
            <div className={location.pathname == allSlug ? "active-sidebar-font" : "passive-sidebar-font"}>
              All {Title}
            </div>
          </Link> */}
          <Link className={location.pathname == `/${newSlug}` ? "Link active-sidebar" : "Link passive-sidebar"}
            // style={{ paddingLeft: "50px" }}
            to={newSlug}
          >
            <div style={{ paddingLeft: "20px" }} className={location.pathname == `/newSlug` ? "active-sidebar-font" : "passive-sidebar-font"}>
              &nbsp; &nbsp;<i className={"fa fa-circle"} style={{ fontSize: "10px" }} />
              &nbsp;Add New {Title}
            </div>
          </Link>
        </>
      }
    </div>
  )
}
export default function SideBar({ setMenuExpand }) {
  const LoginUser = useSelector((state) => state.LoginState);
  const [Menu, setMenu] = React.useState(false)
  const location = useLocation()

  const [ExamExpand, setExamExpand] = React.useState(false)
  const [CBTExamExpand, setCBTExamExpand] = React.useState(false)
  const [TextExamExpand, setTextExamExpand] = React.useState(false)
  const [DemoExamExpand, setDemoExamExpand] = React.useState(false)
  const [DailyExamExpand, setDailyExamExpand] = React.useState(false)
  const [ChapterWiseExamExpand, setChapterWiseExamExpand] = React.useState(false)
  const [BookExpand, setBookExpand] = React.useState(false)
  const [StudentExpand, setStudentExpand] = React.useState(false)
  const [BatchExpand, setBatchExpand] = React.useState(false)
  const [DailyResultsExpand, setDailyResultsExpand] = React.useState(false)
  const [ExamResultsExpand, setExamResultsExpand] = React.useState(false)

  return (
    <div>
      <div className="small-display-none">
        <div style={{
          justifyContent: 'center', display: 'flex', padding: "30px",
        }}>
          <img
            src={LogoURL}
            style={{ width: "100px", height: "100px", objectFit: 'contain' }}
          />
        </div>
      </div>

      <div className="small-display-block">
        <div className={"row passive-sidebar"} onClick={() => { setMenuExpand(false) }}>
          <div className={"passive-sidebar-font"}>
            <i className="fa fa-close" style={{ fontSize: "16px" }} />&nbsp; &nbsp;Close
          </div>
        </div>
      </div>
      {LoginUser.userRole == "author" &&
        <>
          <div style={{}}>
            <SideBarBlock
              allSlug={slugs.all_batch} newSlug={slugs.new_batch}
              location={location} Title={"Kelas"}
              icon={""}
              Expand={BatchExpand}
              setExpand={setBatchExpand}
            />
          </div>
          <SideBarBlock
            allSlug={slugs.all_students} newSlug={slugs.new_student}
            location={location} Title={"Siswa Per Kelas"}
            icon={"fa fa-circle"}
            Expand={StudentExpand}
            setExpand={setStudentExpand}
          />
          <SideBarBlock
            allSlug={slugs.all_daily_exam} newSlug={slugs.new_daily_exam}
            location={location} Title={"Tryout Mingguan"}
            icon={"fa fa-circle"}
            Expand={DailyExamExpand}
            setExpand={setDailyExamExpand}
          />
          <SideBarBlock
            allSlug={slugs.all_daily_results} newSlug={""}
            location={location} Title={"History Per kelas"}
            icon={"fa fa-circle"}
            Expand={ExamExpand}
            setExpand={setExamExpand}
          />
          <div style={{ marginTop: "20px" }}>
            <div className={"passive-sidebar-font"}>
              &nbsp; &nbsp;
              &nbsp; Pemantapan
            </div>
          </div>
          <SideBarBlock
            allSlug={slugs.all_exam} newSlug={slugs.new_exam}
            location={location} Title={"Soal Pemantapan"}
            icon={"fa fa-circle"}
            Expand={ExamExpand}
            setExpand={setExamExpand}
          />
          <SideBarBlock
            allSlug={slugs.all_results} newSlug={""}
            location={location} Title={"History Pemantapan"}
            icon={"fa fa-circle"}
            Expand={BatchExpand}
            setExpand={setBatchExpand}
          />
          <div style={{ marginTop: "20px" }}>
            <SideBarBlock
              allSlug={slugs.all_cbt_exam} newSlug={slugs.new_cbt_exam}
              location={location} Title={"UBT Special"}
              icon={""}
              Expand={DemoExamExpand}
              setExpand={setDemoExamExpand}
            />
          </div>
          <div style={{ marginTop: "20px" }}>

          </div>
          <div style={{ marginTop: "20px" }}>

            <div className={"passive-sidebar-font"}>
              &nbsp; &nbsp;
              &nbsp; Materi Belajar
            </div>
          </div>
          <SideBarBlock
            allSlug={slugs.all_books} newSlug={slugs.new_book}
            location={location} Title={"Text Book"}
            icon={"fa fa-circle"}
            Expand={BookExpand}
            setExpand={setBookExpand}
          />
          <Link to={"/PDFSingleApp/132"}>
            <div className={"passive-sidebar passive-sidebar-font"}>
              &nbsp; &nbsp;<i className={"fa fa-circle"} style={{ fontSize: "10px" }} />
              &nbsp; 비슷말
            </div>
          </Link>
          <Link to={"/PDFSingleApp/133"}>
            <div className={"passive-sidebar passive-sidebar-font"}>
              &nbsp; &nbsp;<i className={"fa fa-circle"} style={{ fontSize: "10px" }} />
              &nbsp; 반댓말
            </div>
          </Link>
          <Link to={"/PDFSingleApp/134"}>
            <div className={"passive-sidebar passive-sidebar-font "}>
              &nbsp; &nbsp;<i className={"fa fa-circle"} style={{ fontSize: "10px" }} />
              &nbsp; 관계있는 것
            </div>
          </Link>
          <Link to={"/Images"}>
            <div className={"passive-sidebar passive-sidebar-font"}>
              &nbsp; &nbsp;<i className={"fa fa-circle"} style={{ fontSize: "10px" }} />
              &nbsp; Images
            </div>
          </Link>
          <div className="row" className="active-sidebar" style={{ paddingLeft: 20 }}>
            Student Panel
          </div>
        </>
      }
      <SideBarBlock
        allSlug={"/"} newSlug={""}
        location={location} Title={"Dashboard "}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      />
      <SideBarBlock
        allSlug={slugs.daily_exam} newSlug={""}
        location={location} Title={"Kelas"}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      />
      <SideBarBlock
        allSlug={slugs.exam} newSlug={""}
        location={location} Title={"Soal Pemantapan"}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      />
      <SideBarBlock
        allSlug={slugs.cbtexam} newSlug={""}
        location={location} Title={"UBT Special"}
        icon={""}
        setExpand={setCBTExamExpand} Expand={CBTExamExpand}
        setMenuExpand={setMenuExpand}
      />
      <SideBarBlock
        allSlug={slugs.daily_exam_result_list} newSlug={""}
        location={location} Title={"History Per kelas"}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      />
      <SideBarBlock
        allSlug={slugs.exam_result_list} newSlug={""}
        location={location} Title={"History Pemantapan"}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      />
      <SideBarBlock
        allSlug={slugs.book_list_user} newSlug={""}
        location={location} Title={"Text Books"}
        icon={""}
        setExpand={setBatchExpand} Expand={BatchExpand}
        setMenuExpand={setMenuExpand}
      />
      <SideBarBlock
        allSlug={"/profile"} newSlug={""}
        location={location} Title={"Profile"}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      />
      {/* <SideBarBlock
        allSlug={"/"} newSlug={""}
        location={location} Title={"Dashboard "}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      />
      <SideBarBlock
        allSlug={slugs.exam} newSlug={""}
        location={location} Title={"All Exam "}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      />
      <SideBarBlock
        allSlug={slugs.textbookexam} newSlug={""}
        location={location} Title={"Latihan Migguan"}
        icon={""}
        setExpand={setTextExamExpand} Expand={TextExamExpand}
        setMenuExpand={setMenuExpand}
      />
      <SideBarBlock
        allSlug={slugs.cbtexam} newSlug={""}
        location={location} Title={"UBT Special"}
        icon={""}
        setExpand={setCBTExamExpand} Expand={CBTExamExpand}
        setMenuExpand={setMenuExpand}
      />
      <SideBarBlock
        allSlug={slugs.exam_result_list} newSlug={""}
        location={location} Title={"All Exam Results"}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      />
        <SideBarBlock
        allSlug={slugs.book_list_user} newSlug={""}
        location={location} Title={"Text Books"}
        icon={""}
        setExpand={setBatchExpand} Expand={BatchExpand}
        setMenuExpand={setMenuExpand}
      />
      <SideBarBlock
        allSlug={"/profile"} newSlug={""}
        location={location} Title={"Profile"}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      /> */}
    </div>
  )
}