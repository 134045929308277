import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsGETApiAuth, AdsPUTApi } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";


export default function AllResults() {
  const [Projects1, setProjects1] = React.useState([])
  const [Projects2, setProjects2] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [StudentsList, setStudentsList] = React.useState([])
  const [ExamType, setExamType] = React.useState([])
  const [ExamList, setExamList] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const [AllTotal, setAllTotal] = React.useState(0)
  const [serachStudent, setSearchStudent] = React.useState("")
  const [serachExam, setSearchExam] = React.useState("")
  const [serachExamID, setSearchExamID] = React.useState("")
  const [SubscriberTotal, setSubscriberTotal] = React.useState(0)
  const [StudentTotal, setStudentTotal] = React.useState(0)
  const [AdminTotal, setAdminTotal] = React.useState(0)
  const [ExamTotal, setExamTotal] = React.useState(0)
  const [choose, setChoose] = React.useState(0)
  const [studentSelect, setStudentSelect] = React.useState("")
  const [bulkDelete, setBulkDelete] = React.useState(false)

  const GetAllProjects = (params, setData, slug, setTotal) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slug)
      .then((res) => {
        console.log(res.data)
        setData(res.data)
        setTotal(res.headers["x-wp-total"])
        if (slug == slugs.db_slug_paid_results)
          setCustomersList(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        if (slug == slugs.db_slug_paid_results)
          setReload(false)
      })
  }

  useEffect(() => {
    GetAllProjects({
      per_page: 4000,
      // _fields: "id,title,slug,meta.student_id_results,meta.exam_id_results,meta.exam_total_results,meta.exam_date_results,meta.exam_name_results,meta.exam_type_results,meta.exam_sub_type_results"
      _fields: "id,title,slug,meta,exam_name_results,meta.user_id,meta.student_id_results,meta.exam_id_results,meta.exam_total_results,meta.exam_date_results"
    }, setProjects1, slugs.db_slug_paid_results, setAllTotal)
    GetAllProjects({
      per_page: 500,
      _fields: "id,title,slug,meta.available_unavailable,meta.exam_type"
    }, setExamList, slugs.db_slug_paid_exam, setExamTotal)
    GetAllProjects({
      per_page: 4900,
      _fields: "id,title,slug,meta.user_phone_number,meta.batch_name,meta.user_id"
    }, setStudentsList, slugs.db_slug_user_data, setStudentTotal)
    // GetTotal("student", setStudentTotal, slugs.db_slug_user_data)
    // GetTotal("", setExamTotal, slugs.db_slug_paid_exam)
    // GetTotal("", setAllTotal, slugs.db_slug_paid_results)
  }, [])

  const columnsExam = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
      width: "100px"
    },
    {
      id: 31,
      name: "Title",
      selector: (row) => row.title.rendered,
      sortable: true,
      reorder: true,
    },
    {
      id: 33,
      name: "Availability",
      selector: (row) => row.meta.available_unavailable,
      sortable: true,
      reorder: true,
      width: "150px"
    },
    {
      id: 34,
      name: "Type",
      selector: (row) => row.meta.exam_type,
      sortable: true,
      reorder: true
    },
    {
      id: 34,
      name: "Total Exams",
      selector: (row) => CustomersList.filter(a => a.meta.exam_id_results == row.id).length,
      sortable: true,
      reorder: true
    },
    {
      id: 3,
      name: "Choose",
      right: true,
      cell: (row) => <div style={{ cursor: "pointer" }}
        onClick={() => {
          setProjects1(CustomersList.filter(a => a.meta.exam_id_results == row.id))
          setChoose(0)
        }}
      >
        Choose
      </div>,

    }
  ];
  const columnsStudent = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.meta.user_id,
      sortable: true,
      reorder: true,
    },
    {
      id: 31,
      name: "Title",
      selector: (row) => row.title.rendered,
      sortable: true,
      reorder: true
    },
    {
      id: 312,
      name: "Phone",
      selector: (row) => row.meta.user_phone_number,
      sortable: true,
      reorder: true
    },
    {
      id: 32,
      name: "Batch",
      selector: (row) => row.meta.batch_name,
      sortable: true,
      reorder: true
    },
    {
      id: 3421,
      name: "Total Exams",
      selector: (row) => CustomersList.filter(a => a.meta.student_id_results == row.meta.user_id).length,
      sortable: true,
      reorder: true
    },
    {
      id: 3123,
      name: "Choose",
      right: true,
      cell: (row) => <div style={{ cursor: "pointer" }}
        onClick={() => {
          setProjects1(CustomersList.filter(a => a.meta.student_id_results == row.meta.user_id))
          setChoose(0)
        }}
      >
        Choose
      </div>,

    }
  ];
  const columns = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
      width: "100px",
      padding: 0,
      compact: true,
    },
    {
      id: 31,
      // name: "Exam",
      name: "Set Soal",
      selector: (row) => row.meta.exam_name_results,
      sortable: true,
      reorder: true,
      width: "150px",
      padding: 0,
      compact: true,
    },
    {
      id: 313,
      // name: "Student Name",
      name: "Nama Siswa",
      selector: (row) =>
        StudentsList.filter(a => a.meta.user_id == row.meta.student_id_results).length !== 0 ?
          StudentsList.filter(a => a.meta.user_id == row.meta.student_id_results)[0].title.rendered : ""
      ,
      padding: 0,
      sortable: true,
      reorder: true,
      compact: true,
    },
    {
      id: 312,
      name: "Set UBT ID",
      selector: (row) => row.meta.exam_id_results,
      sortable: true,
      compact: true,
      reorder: true
    },
    {
      id: 314,
      // name: "Total Score",
      name: "Point",
      // selector: (row) => row.meta.exam_total_results,
      // selector: (row) => (row.meta.exam_total_results / JSON.parse(row.meta.exam_answers_array_results).length * 100).toFixed(2),
      selector: (row) => (row.meta.exam_total_results / 40 * 100).toFixed(2),
      sortable: true,
      compact: true,
      reorder: true
    },
    {
      id: 315,
      // name: "Date",
      name: "Tanggal",
      selector: (row) => row.meta.exam_date_results,
      sortable: true,
      compact: true,
      reorder: true
    },
    {
      id: 32,
      // name: "Delete",
      name: "Hapus",
      right: true,
      cell: (row) => <div style={{ cursor: "pointer" }}
        onClick={() => {
          AdsPUTApi({
            status: "draft"
          }, slugs.db_slug_paid_results, row.id)
            .then((res) => {
              setSearchStudent("")
              setSearchExam("")
              setSearchExamID("")
              // GetAllProjects({
              //   per_page: 4900,
              //   _fields: "id,title,slug,meta.student_id_results,meta.exam_id_results,meta.exam_total_results,meta.exam_date_results,meta.exam_name_results"
              // }, setProjects1, slugs.db_slug_paid_results, setAllTotal)
              GetAllProjects({
                per_page: 4900,
                // _fields: "id,title,slug,meta.student_id_results,meta.exam_id_results,meta.exam_total_results,meta.exam_date_results,meta.exam_name_results,meta.exam_type_results,meta.exam_sub_type_results"
                _fields: "id,title,slug,meta"
              }, setProjects1, slugs.db_slug_paid_results, setAllTotal)

            })
            .catch((err) => {
              console.log(err)
            })
        }}
      >Hapus</div>,

    },
    {
      id: 32,
      // name: "View",
      name: "Lihat",
      right: true,
      cell: (row) => <Link to={`${slugs.result_admin_view}/${row.slug}`}>Lihat</Link>,

    }
  ];
  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          {/* <h2>All Results</h2> */}
          <h2>Hasil Ujian</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Hasil Ujian
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div style={{ display: "inline", cursor: "pointer" }}>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setChoose(0)
                setProjects1(CustomersList)
              }}
            >
              {/* All ({AllTotal}) | */}
              Semua ({AllTotal}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setChoose(1)
              }}
            >
              {/* Exams ({ExamTotal}) | */}
              Soal UBT ({ExamTotal}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setChoose(2)
              }}
            >
              {/* Students ({StudentTotal}) | */}
              Siswa ({StudentTotal}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setProjects1(CustomersList)
              }}
            >
              Semua Hasil
            </div>
          </div>
        </div>
        <div className="col-md-6 row">
          <div className="col-1" />
          {Projects2.length == 0 ?
            <div className="col-3" />
            :
            <div className="col-3">
              <div style={{ display: "inline-block", margin: "0px 5px", cursor: "pointer" }}
                onClick={() => {
                  setBulkDelete(true)
                }}
              >
                Hapus Semua
              </div>
            </div>
          }
          <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachExam} placeholder="Exam Name"
            onChange={(event) => {
              setSearchExam(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.exam_name_results.includes(event.target.value)))
            }}
          />
          <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachExamID} placeholder="Exam ID"
            onChange={(event) => {
              setSearchExamID(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.exam_id_results.includes(event.target.value)))
            }}
          />
          <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachStudent} placeholder="Student ID"
            onChange={(event) => {
              setSearchStudent(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.student_id_results.includes(event.target.value)))
            }}
          />
        </div>
      </div>


      <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
        {choose == 0 &&
          <DataTable
            columns={columns}
            data={Projects1}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            // sortIcon={<SortIcon />}
            pagination
            selectableRows
            onSelectedRowsChange={(r) => {
              console.log("asdasd", r)
              setProjects2(r.selectedRows)
            }}
          />
        }
        {choose == 1 &&
          <>
            <DataTable
              columns={columnsExam}
              data={ExamList}
              defaultSortFieldId={1}
              defaultSortAsc={false}
              striped
              // sortIcon={<SortIcon />}
              pagination
              selectableRows
            />
          </>
        }
        {choose == 2 &&
          <DataTable
            columns={columnsStudent}
            data={StudentsList}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            // sortIcon={<SortIcon />}
            pagination
            selectableRows
          />
        }
        {bulkDelete &&
          <section className="modal" >
            <div className="row">
              <div className="col-md-4 col-1"></div>
              <div className="col-md-4 col-10  shadow bg-white">
                <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                  <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                    onClick={() => {
                      setBulkDelete(false)
                    }}
                  >X</div>
                  Apakah Anda benar-benar ingin menghapus semua hasil dari pengguna ini??<br />
                  Menghapus ujian tidak dapat diubah..
                  <tr>
                    <td colSpan={6}>
                      <div className="row">
                        <div className="col-1" />
                        <div className="col-5 center " >
                          <div className="sign-in-button-4" style={{ width: "80%" }}
                            onClick={() => {
                              setBulkDelete(false)
                            }}
                          >
                            Batal
                          </div>
                        </div>
                        <div className="col-5 center">
                          <div className="sign-in-button-4" style={{ width: "80%" }}
                            onClick={() => {
                              setBulkDelete(false)
                              for (let i = 0; i < Projects2.length; i++) {
                                setReload(true)
                                AdsPUTApi({
                                  status: "draft"
                                }, slugs.db_slug_paid_results, Projects2[i].id)
                                  .then((res) => {
                                    if (i == Projects2.length - 1) {
                                      setSearchStudent("")
                                      setSearchExam("")
                                      setSearchExamID("")
                                      setStudentSelect(``)
                                      GetAllProjects({
                                        per_page: 4900,
                                        // _fields: "id,title,slug,meta.student_id_results,meta.exam_id_results,meta.exam_total_results,meta.exam_date_results,meta.exam_name_results,meta.exam_type_results,meta.exam_sub_type_results"
                                        _fields: "id,title,slug,meta"
                                      }, setProjects1, slugs.db_slug_paid_results, setAllTotal)
                                      GetAllProjects({
                                        per_page: 500,
                                        _fields: "id,title,slug,meta.available_unavailable,meta.exam_type"
                                      }, setExamList, slugs.db_slug_paid_exam, setExamTotal)
                                      GetAllProjects({
                                        per_page: 4900,
                                        _fields: "id,title,slug,meta.user_phone_number,meta.batch_name,meta.user_id"
                                      }, setStudentsList, slugs.db_slug_user_data, setStudentTotal)
                                    }
                                  })
                                  .catch((err) => {
                                    console.log(err)
                                  })

                              }
                            }}
                          >
                            Hapus semua
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </div>
              </div>
            </div>
          </section>

        }
      </div>
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}